.divImgMaquinasGuardadas {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.divImgMaquinasNuevas {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.divTodosPdf {
    width: 100% !important;
}

.divBloquePdf {
    width: 50%;
}

.divRequisitos {
    border-left: 1px solid #9A9A9A;
    padding-left: 10px;
}

.botonPdf {
    font-size: 11px !important;
    color: black !important;
    max-height: 60px;
    max-width: 280px;
    overflow: hidden;
    line-height: 1.2;
    text-transform: inherit;
}

.divDocumentos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.divListaPdf {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.botonListaPdf {
    max-width: 500px !important;
    text-align: left;
}

.botonEspacio {
    margin-bottom: 6px !important;
}

#dialogCrearParte {
    min-height: 400px !important;
}

#dialogCrearParte>div>div {
    min-width: 70% !important;
}

.imagenGaleriaGrande {
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
}

.containerInfoMaquinaPartes {
    overflow-y: auto !important;
    /* max-height: 400px !important; */
    min-height: 50vh !important;
    height: 50vh !important;
}

.containerInfoMaquina {
    overflow-y: auto !important;
    /* max-height: 400px !important; */
    min-height: 54vh !important;
    height: 54vh !important;
}

.margenBotonLugar {
    margin: 10px 10px !important;
}

#duplicarCaracteristicasGrupo>div>div {
    min-width: 60% !important;
    /* max-height: 90%; */
}

#dialogoNewMaquina>div>div {
    min-width: 70% !important;
    min-height: 67% !important;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 96% !important;
}

.containerNewMaquina {
    overflow-y: auto !important;
    min-height: 60vh !important;
    height: 60vh !important;
}

.informativo {
    padding-bottom: 6px;
}
.divCheck {
    width: 60% !important;
}