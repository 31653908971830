.full-width {
    width: 100%;
}

.juntar {
    width: 38px;
    height: 30px;
    font-size: .8rem !important;
    cursor: pointer;
    text-align: center;
    background-color: #f3f4f6 !important;
    color: rgb(0, 0, 0) !important;
    line-height: 35px !important;
    min-width: 40px !important;
    padding: 0 !important;
    margin: 1px !important;
}

.pendiente {
    background-color: #f3f4f6 !important;
    color: rgb(0, 0, 0) !important;
}

.limpia {
    background-color: #008000 !important;
    color: white !important;
}

.noMolestar {
    background-color: #b74ba5 !important;
    color: white !important;
}

.bloqueada {
    background-color: #8b8b8b !important;
    color: white !important;
}

.colorSalida {
    background-color: #d13321 !important;
    color: white !important;
}

.colorSalidaHoy {
    background-color: #ffa500 !important;
    color: black !important;
}

.MuiBadge-badge {
    margin-top: 7px !important;
    margin-right: 7px !important;
    height: 15px !important;
    width: 15px !important;
    font-size: 0.55rem !important;
    min-width: 0px !important;

}

.hab-texto {
    margin-top: 7px !important;
}

.planta {
    width: 5%;
    margin: 1px !important;
    padding: 0 !important;
    flex: none !important;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6 !important;
}

.piso-bloque {
    display: flex;
}

.fifty {
    width: 50%;
    float: right;
    margin-right: 10px !important;
}


/*    background-color: #00A2DC!important;
*/
.juntar:hover {
    background: #00a2dc !important;
    border-color: #00a2dc !important;
    color: white !important;
}

.noEncontrado {
    height: 100%;
    width: 100%;
    margin-top: 10%;
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 10%;
}

.icon-button-Editar {
    width: 177px !important;
    height: 250px !important;
    filter: invert(44%) sepia(55%) saturate(2307%) hue-rotate(164deg) brightness(95%) contrast(105%);
}

.tituloBloque {
    text-transform: capitalize;
    margin: 0;
    margin-left: 15px;
}

.pisosLeft {
    width: 50%;
}

.pisosContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

.iconSvg {
    width: 19px !important;
    padding: 0px;
    margin: -5px;
    font-size: 1rem !important;
}

.contenidoIzquierda {
    justify-content: flex-start !important;
}

@media screen and (max-width: 800px) {
    .pisosContainer {
        flex-direction: column;
    }

    .pisosLeft {
        width: 100%;
    }

}

.vacaciones-mes-bloque {
    display: flex;
    height: 7vh;
    margin: 5px 0px !important;
}
.vacaciones-mes-bloque-texto{
    width: 5%;
    margin: 0px !important;
    padding: 0 !important;
    flex: none !important;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6 !important;
}

.vacaciones-dia {
    display: flex;
}
.vacaciones-dia-flex-absolute {
    position: absolute;
    width: 100%;
    display: flex;
    top: 0%;
    height: 40%;
    flex-direction: column;
}

.vacaciones-button {
    width: 38px;
    height: auto;
    font-size: .8rem !important;
    cursor: pointer;
    text-align: center;
    background-color: #f3f4f6 !important;
    color: rgb(0, 0, 0) !important;
    line-height: 35px !important;
    min-width: 40px !important;
    padding: 0 !important;
    border-radius: 0px !important;
    display: flex !important;
    /* height: auto; */
    align-items: flex-end !important;
}
.vacaciones-button:hover{
    background-color: #f2efe8 !important;
}
.vacaciones-texto-absoluto {
    background: #00a2dc;
    color: #00a2dc;
    width: 100%;
    margin: 5% 0px;
    height: inherit;
    line-height: 3px;
    height: 3px;
}

.finde {
    background-color: #c0c1c3 !important;
}
.sin-purgar{
    background-color: #d13321 !important;
}
.purgada{
    background-color: #008000 !important;

}