.tituloHabitacion {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.infoParteId {
    flex: none !important;
    padding-right: 10px;
}

.infoParteId>span {
    visibility: hidden;
}

.infoParteIdHijo {
    margin: 2px 0px 2px 8px;
    background-color: #f5f4f4 !important;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
}

.infoParteIdHijo>li {
    padding: 0 !important;
}

.pruebecita {
    background-color: red;
}

.bordeParte {
    border-top: outset;
}

.infoParteRoom {
    width: 20%;
    margin: 0px !important;
    color: black;
}

.centrarInfoParteRoom {
    text-align: center;
}

.centrarContenido{
    justify-content: center;
}

.usuarioImagen {
    display: flex;
    text-align: start;
    align-items: center;
}
.usuarioImagenLeft {
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: flex-end !important;
}

.infoParte {
    box-shadow: 0px 0px 1px rgb(0 0 0 / 33%);
    padding: 0px !important;
    margin: 2px 0px !important;
}

.infoTitulo {
    width: 50%;
}

.infoHistorico {
    padding-left: 15px;
}

.usuarioHistorico {
    justify-content: start;
    padding-left: 10px;
}

.nuevoParteHab {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    max-height: 370px;
}

.anchoTextField {
    width: 30%;
    margin: 0px !important;
    margin-top: 10px !important;
}

.sinMargen {
    margin: 0px;
}

.containerDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.divImgPdf {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divPdf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.divContenedorImg {
    width: min-content;
}

.divAnchoImagen {
    width: 45%;
}

.divPdfTipoParte {
    display: flex;
    width: 65%;
    justify-content: space-between;
}

.nuevaImagen {
    max-height: 166px;
    max-width: 250px;
}

.obsNuevoParteHab {
    margin-top: 10px !important;
    height: 40%;
    width: 100%;
}

.obsNuevoParteHab>div {
    height: 100%;
}

.obsNuevoParteHab>div>textarea {
    height: 100% !important;
}

.contenedorNuevoParteHab {
    width: 60%;
    padding-left: 20px;
}

.disabled {
    background-color: #00698f !important;
    color: white !important;
    border-color: white !important;
}

.colorBoton {
    background: #00a2dc !important;
    color: white !important;
    border-color: white !important;
}

.activo {
    background: #e0a800 !important;
    background-color: #e0a800 !important;
}

.nombrePdf {
    font-size: 11px !important;
    color: black !important;
    max-height: 60px;
    max-width: 220px;
    overflow: hidden;
    line-height: 1.2;
    text-transform: inherit;
    padding: 0 !important;
}

.iconX {
    padding: 2px 4px 2px 4px !important;
}

.alturaDivHab {
    /* max-height: 65vh !important;
    height: 65vh !important; */
    max-height: 67vh !important;
    height: 67vh !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* overflow-y: hidden !important;
    */
}

.anchoMitad {
    width: 50%;
}

.bloqueTipoParte>div {
    width: 100%;
    margin-top: 8px;
}
.divAmenities{
    display: flex;
}

.listaAmenities {
    width: 50%;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 60vh;
    overflow-y: auto;
    max-height: 60vh;

}

.lineaAmenaties {
    padding: 4px 0px !important;
}

.cantidadAmenities {
    width:70px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.textoAmenities {
    padding-left: 10px;
}

.checkboxAmenities {
    padding: 0px 4px 0px 0px !important;
    margin: 0px !important;
}
.texto-beneficio {
    border-left: 2px solid rgba(0, 0, 0, 0.04);
    padding-right: 10px;
    margin-left: 10px;
} 
.bloqueEstadoHab{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 450px;
    justify-content: space-between;
}
.botonera-separado{
    width: 250px;
    display: flex;
    /* justify-content: space-between; */
}