.subiryconsultar {
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 12px !important;
  box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px
}

.subiryconsultarHeader {
  height: 50px;
  display: flex;
  box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
  justify-content: center;
}

.subiryconsultarContainer {
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
}

.subiryconsultarRow {
  display: flex;
  flex-direction: row;
}

.dycinput {
  width: 25%;
  padding: 4px;
  display: flex;
  /* height: 37px; */
  align-items: center;
  justify-content: space-evenly;
}

.dycinputfl {
  width: 25%;
  padding: 4px;
  display: flex;
  /* height: 37px; */
  align-items: flex-start;
  justify-content: space-evenly;
}

.dycbotonera {
  width: 25%;
  padding: 4px;
  display: flex;
  /* height: 37px; */
  align-items: flex-start;
  justify-content: space-evenly;
}

.dycobservaciones {
  width: 30%;
  padding: 4px;
  display: flex;
  /* height: 37px; */
  align-items: center;
  justify-content: space-evenly;
}

.dycbotoneraseparado {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dycobs {
  margin: 0 !important;
}