.divConfiguracion {
    height: 96vh;
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    background-color: white;
    border-radius: 12px !important;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
}

.containerInfoConf {
    padding-left: 20px !important;
}

.listaUnidades {
    height: 66vh;
    overflow-y: auto;
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    padding-right: 10px !important;
    margin-right: 20px !important;
}

.borrarIcono {
    color: black !important;
    background-color: #f3f4f6 !important;
    padding: 6px 16px !important;
    border-radius: 0px !important;
}

.borrarIcono :hover {
    color: #db5e5c !important;
    filter: invert(44%) sepia(55%) saturate(2307%) hue-rotate(342deg) brightness(95%) contrast(105%) !important;
}

.alturaMinima {
    height: 100% !important;
    min-height: 100% !important;
}

.alturaMinimaDiv {
    height: 92% !important;
    min-height: 92% !important;
}

.alturaDivLista {
    max-height: 90vh !important;
    height: 90vh !important;
}
.containerTablas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.minimoAncho{
    width: 50% !important;
}
.minimoAnchoContenido{
    min-width: 500px !important;
}
#editarTipoCaracteristica>div>div {
    min-width: 50% !important;
    max-height: 90%;
}
.alturaTipos{
    height: 560px !important;
}
.sinMargen{
    padding: 0px !important;
}
.dialogListaCaracteristicas{
    min-height: 600px !important;
}
#listaCaracteristicasGrupo>div>div {
    min-width: 80% !important;
    max-height: 90%;
}
#crearFichaGrupo>div>div {
    min-width: 50% !important;
}
#dialogBorrarFichaGrupo>div>div {
    min-width: 60% !important;
}
.infoBorrar{
    display: flex;
    flex-direction: column;
    border-right: 1px solid;
    padding-right: 5px;
    width: 100% !important;
    align-items: center;
    font-size: 12px;
}
.infoBorraPrimero{
    border-left: 1px solid;
}
#editarFichaGrupo>div>div {
    min-width: 50% !important;
}

.bloqueCaracteristicaGrupo {
    display: flex;
    /* justify-content: center; */
}
.bloqueCaracteristicaGrupo>div {
    width: 20%;
    margin: 10px 10px !important;
}