.centrar {
  text-align: center;
}

.custom-button {
  width: 120px !important;
  height: 120px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.custom-button-text {
  font-size: 48px !important;
  font-weight: bold !important;
  
}

.custom-button-label {
  font-size: 12px !important;
  background-color: rgba(255, 255, 255, 0.493);
  padding: 0;
  width: 100%;    
  height: 70px;
  padding: 7px;
}
.homeContainer{
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 12px !important;
  box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px
}
.homeContainerHeader{
  height: 70px;
  display: flex;
  box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
  justify-content: center;
}

.containerButtonContainer {
  padding: 25px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.nuevo-button {
  background-color: #d13321 !important;
  color: white !important;
}
.asignado-button {
  background-color: #a99b33 !important;
  color: white !important;
}
.en-ejecucion-button {
  background-color: #00a2dc !important;
  color: white !important;
}

.terceros-button {
  background-color: #ffa500 !important;
  color: white !important;
}
.anulado-button {
  background-color: #808080 !important;
  color: white !important;
}
.cerrado-button {
  background-color: #008000 !important;
  color: white !important;
}

.nuevo-button:hover {
  background-color: #9e2618 !important;
}
.asignado-button:hover {
  background-color: #756c23 !important;
}

.en-ejecucion-button:hover {
  background-color: #007ca9 !important;
}

.terceros-button:hover {
  background-color: #cc8400 !important;
}
.anulado-button:hover {
  background-color: #4d4d4d !important;
}
.cerrado-button:hover {
  background-color: #004d00 !important;
}
.enlaces-button{
    text-decoration: none !important;
}