.blanco {
    color: black !important;
}

.nuevo {
    /* background-color: #d13321; */
    color: #d13321;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #d13321 0%, #d13321 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.asignado {
    /* background-color: #a99b33; */
    color: #a99b33;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #a99b33 0%, #a99b33 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.en-ejecucion,
.en\ \ ejecucion,
.en\ ejecucion,
.en\-ejecucion {
    /* background-color: #00a2dc; */
    color: #00a2dc;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #00a2dc 0%, #00a2dc 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.terceros {
    /* background-color: #ffa500; */
    color: #ffa500;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #ffa500 0%, #ffa500 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.cerrado {
    /* background-color: #008000; */
    color: #008000;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #008000 0%, #008000 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.anulado {
    /* background-color: #808080; */
    color: #808080;
    padding: 0px !important;
    /* background: linear-gradient(90deg, #808080 0%, #808080 0.6%, rgb(255 0 0 / 0%) 0.7%) */
}

.whitespace {
    white-space: normal !important;
}

.header-grid {
    text-align: center !important;
}

.MuiDataGrid-columnHeader:focus,
.css-11o4y5n-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-columnHeader:focus,
.css-bn1oxg-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.boton {
    /* border: 1px solid #00A2DC !important; */
    color: black !important;
    background-color: #f3f4f6 !important;
    padding: 6px 16px !important;
    border-radius: 0px !important;

}
.boton-disabled{
    background-color: rgba(0, 0, 0, 0.12) !important;
    padding: 7px 16px !important;
    cursor: initial !important
}
.separador{
    border-right: 1px solid #e0e0e0;
}
.MuiToggleButton-root{
border: none !important;
}
.MuiToggleButton-root:not(:last-of-type){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #e0e0e0  !important;
}
.boton:hover{
    
    background-color: #ebebeb !important;
}
.boton-grande{
    
    color: black !important;
    background-color: #00a2dc !important;
    width: 80px;
}
.boton-grande:hover{
    background-color: #007ca9 !important;
}
.editarLote{
    color: black !important;
    background-color: #00a2dc !important;
}
.editarLote:hover{
    background-color: #007ca9 !important;
}
/* .botonera {
    margin-right: 40px; 
} */

.icon-button {
    filter: invert(44%) sepia(55%) saturate(2307%) hue-rotate(164deg) brightness(95%) contrast(105%);
}

.icon-button-small {
    filter: invert(44%) sepia(55%) saturate(2307%) hue-rotate(164deg) brightness(95%) contrast(105%);
    font-size: 12px !important;
}
.icon-button-big {
    color: white;
    font-size: 24px !important;
}
.icon-button-disabled{
    color: white;
}
.pintado {
    background: #e0a800 !important;
    border-color: #ffc107 !important;
}

.pintado:hover {
    background: #d29e00 !important;
    border-color: #ffd453 !important;
}

.pintado>svg {
    filter: none !important;
}

.containerAcciones {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    min-height: 64px;
    max-height: 64px;
    justify-content: space-between;
}


.containerAcciones>.boton {
    margin-left: 10px !important;
}

.containerAcciones>div>button {
    border-color: #e0e0e0 !important;
}
/* .containerAcciones>div>button:hover {
    background-color: #ebebeb !important;
} */

.containerAccionesLeft {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 64px;
    max-height: 64px;
    justify-content: flex-start;
    padding: 7px 18px;
}

.containerAccionesLeft>.boton {

    margin-left: 10px !important;
}

.containerAccionesLeft>div>button {
    border-color: #e0e0e0 !important;
}

.primerFiltro {
    margin-top: 5px;
}

#dialogo>div>div {
    min-width: 80% !important;
    /* Antes era 70 */
    max-height: 96%;
}

#dialogoFiltros>div>div {
    min-width: 70% !important;
}

#dialogEditarParte>div>div {
    min-width: 70% !important;
    min-height: 67% !important;
    margin-top: auto;
    margin-bottom: auto;
}

#dialogoEditarLotes>div>div {
    min-width: 80% !important;
    /* Antes era 70 */
    max-height: 96%;
}

#confirmEditarLotes>div>div {
    min-width: 80% !important;
    max-height: 96%;
}
.historicoEditarLotes {
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
}
.infoEditarLotes{
    padding-top: 5px;
}

.dispFlex{
    display: flex;
}

.bloque-tercero {
    display: flex;
    width: 50%;;
}

.bloque-tercero>div {
    width: 50%;
    padding: 7px 7px;
    margin: 0;
}

.bloque-tercero>div>label {
    transform: translate(23px, 3px) scale(0.75);
}

.bloque-inputs-filtros {
    display: flex;
    /*justify-content: center;
    */
}

.bloque-inputs-filtros>div {
    width: 15%;
    margin: 10px 10px !important;
}


.divFiltros {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.divFiltrosContainer {
    width: 50%;
    margin: 0px !important;
}

.divFiltrosContainer>h4 {
    margin: 0px;
}

.divFiltrosTextField {
    display: flex;
}

.divFiltrosTextField>div {
    margin: 10px 10px;
    width: 50%;
}
.divFiltrosTitulo {
    display: flex;
}

.divFiltrosTitulo>div {
    margin: 10px 10px;
    width: 100%;
}

.divAplazado {
    display: flex;
    align-items: stretch;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.activo>label {
    transform: translate(14px, -9px) scale(0.75);
}

.buscador {
    width: min-content !important;
    height: min-content !important;
}

.centrar {
    align-self: center;
}

.departamento {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: auto;
}

.botonLista {
    width: 80%;
    padding-bottom: 0 !important;
    padding-top: 0px !important;

}

.divisor {
    border: 1px solid #00A2DC !important;
    padding: 0% !important;
    border-bottom: none !important;
}

.divisorError {
    border: 1px solid red !important;
    padding: 0% !important;
    border-bottom: none !important;
}

.ultimoDiv {
    border-bottom: 1px solid #00A2DC !important;
}

.ultimoDivError {
    border-bottom: 1px solid red !important;
}

.itemBoton {
    margin: 0px !important;
    padding: 0px !important;
    align-items: center !important;
    display: flex !important;
    min-height: 50px !important;
    width: 100% !important;
}

.itemBoton>div {
    margin: 0px !important;
    padding: 0px !important;
}

.center {
    text-align: center;
    width: 100% !important;
}

/*Agrando el tamño del span de los botones de departamentos*/
.center>span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-content-center {
    justify-content: center !important;
}

.pastilla {
    border-radius: 12px;
    width: 95%;
    padding: 0 !important;
    display: flex;
    height: 50%;
    align-items: center;
}
.pastilla-icono{
    width: 22px !important;
    margin: 0 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pastilla-texto{
    width: 100%;
}
.centrado{
    text-align: center;
}

/*
    transform: translate(23px, 3px) scale(0.75);

#dialogo > div> div>div>div>div{
    min-width: 10%!important;
}*/

.editarLoteInfo{
    display: flex;
    justify-content: flex-start;
    max-height: -webkit-fill-available;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.observacionesEditarLote{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    flex-direction: column;
    text-transform: initial;
}
.dialogCrearParte {
    height: 100vh;
}

.MuiDataGrid-cell {
    border: none !important;
    cursor: pointer;
}

.container {
    background-color: #FCFCFC !important;
}

.grid {
    border: none !important;
}

.grid-container {
    height: 830px;
    /* height: calc(100vh - 80px); */
    margin-left: 20px;
    margin-right: 20px;
    background-color: white;
    border-radius: 12px !important;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px
}

.MuiDataGrid-columnHeaders {
    background-color: #f3f4f6;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}


.nuevoParteForm {
    display: flex;
    justify-content: space-between;
    max-height: -webkit-fill-available;

}

.fondogris {
    background-color: #f5f4f4;
}

.nuevoParteBloque {
    width: 33% !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.nuevoParteAltura {
    height: 440px;
}

.nuevoParteText {
    height: 100% !important;
    /* margin: 16px 8px !important; */
    margin-bottom: 0px !important;
}

.nuevoParteText>div {
    height: 100% !important;
}

.nuevoParteText>div>textarea {
    height: 100% !important;
    overflow-x: auto !important;
}

.nuevoParteNumero {
    width: 100% !important;
}

.nuevoParteBuscar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nuevoParteHistorico {
    height: 300px;
    border: 1px solid rgb(136 134 134 / 57%) !important;
    border-radius: 4px;
    overflow-x: auto;
}

.nuevoParteBotonAdjuntar {
    margin: 4px;
    text-align: center;
    align-self: center;
}
.nuevoPartePreviewImagen {
    max-height: 150px;
    max-width: 150px;
    border-radius: 4px;
    align-self: center;
    margin: 4px;
}
.nuevoParteNombrePdf {
    font-size: 11px !important;
    color: black !important;
    max-height: 60px;
    max-width: 280px;
    overflow: hidden;
    line-height: 1.2;
    text-transform: inherit;
    padding: 0 !important;
}
.ocultar {
    display: none;
}

.img-preview {
    max-width: 300px;
    max-height: 150px;
}

.margenDivImagen {
    margin-right: 8px;
}

.margenBloque {
    margin-right: 16px !important;
}

.anchoLabel {
    display: flex;
    justify-content: space-between;
}

.colorGrupo {
    background-color: #00a2dc !important;
    color: white !important;
    border-color: white !important;
    min-width: 100px !important;
}
.colorGrupo:hover {
    background-color: #007ca9 !important;
}
.colorGrupo2 {
    background-color: #00a2dc !important;
    color: white !important;
    min-width: 100px !important;
    border: 0px !important;
}
.colorGrupo2:hover {
    background-color: #007ca9 !important;
}
.ayuda-container {
    background-color: white;
}

.ayuda-container .color {
    width: 20px;
    height: 20px;
    margin: 5px;
}

.color-container {
    display: flex;
    width: 150px;
    align-items: center;
}

.nuevo-fill {
    background-color: #d13321;
}

.asignado-fill {
    background-color: #a99b33;
}

.en-ejecucion-fill {
    background-color: #00A2DC;
}

.terceros-fill {
    background-color: #e0a800;
}

.anulado-fill {
    background-color: #808080;
}

.cerrado-fill {
    background-color: #008000;
}

.MuiDataGrid-row.Mui-selected.nuevo {
    background-color: rgba(209, 51, 33, 0.2) !important;
}

.MuiDataGrid-row.Mui-selected.asignado {
    background-color: rgba(169, 155, 51, 0.2) !important;
}

.MuiDataGrid-row.Mui-selected.en-ejecucion {
    background-color: rgba(0, 162, 220, 0.2) !important;
}

.MuiDataGrid-row.Mui-selected.terceros {
    background-color: rgba(224, 168, 0, 0.2) !important;
}

.MuiDataGrid-row.Mui-selected.anulado {
    background-color: rgba(128, 128, 128, 0.2) !important;
}

.MuiDataGrid-row.Mui-selected.cerrado {
    background-color: rgba(0, 128, 0, 0.2) !important;
}

.tabla-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.botonBorrarFiltros{
    margin-right: 10px;
    background-color: #d13321 !important;
    color: white !important;
    border-color: white !important;
    min-width: 100px !important;
}
.botonBorrarFiltros:hover {
    background-color: #9e2618 !important;
}
.botonCarga{
    height: 20px!important;
    width: 20px!important;
}
.bordeCircular{
    border-radius: 6px !important;
}

.borrarBoton {
    background-color: #00a2dc !important;
}

.borrarBoton:hover {
    background-color: red !important;
}