.test {
    margin: 0 auto !important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
}


#login-logo {
    width: 180px !important;
    display: flex;
    margin: 0 auto;
}

.fullHeight {
    position: absolute;
    top: 0;
    bottom: 0;
}
.login-form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.vertical-divider{
    margin: 0px 40px !important;
}