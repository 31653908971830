.seleccionHotel{
    display: flex;
    justify-content: space-between;
}
.sinEspacio{
    margin: 0px;
    padding: 0px;
}
.colorDefecto{
    color: #000000!important;
}
.colorError{
    color: #fc3c3c!important;
}
.informacionUsuario{
    display: flex;
    flex-direction: column;
}

#dialogPassword>div>div{
    max-width: 380px;
}
.divInfoUsuario{
    width: 45%;
}
.divTextBoton{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.divPassword{
    display: flex;
    flex-direction: column;
}
.passwordBoton{
    background-color: #00a2dc !important;
    color: white !important;
    border-color: white !important;
}
.passwordBoton:hover{
    background: #cc3535 !important;

}
.estiloDivider{
    margin: 6px 0px!important;
}
/*
.divInfoUsuario:read-only{
    background: #acacac;
}*/
.contenedorAjustes{
    height: 100%;
}
.margenBotonGuardar{
    margin-top: 20px;
}


