.lectura-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.lecturas-list-container{
    max-height: 830px;
    overflow-y: scroll;
}