/* Separacion por igual entre inputs en cada fila */
.datos-container {
    margin: -8px 10px;
}

.bloque-inputs {
    display: flex;
}

.bloque-inputs>div {
    width: 25%;
    padding: 7px 7px;
    margin: 0;
}

.bloque-inputs>div>label {
    transform: translate(23px, 3px) scale(0.75);
}

.titulo {
    width: 75% !important;

}

.historial-container {
    max-height: 436px;
    overflow-y: auto;
    overflow-x: hidden;

}

.historial-container>ul>div>div {
    width: 100%;
    margin: 1px;
}

/* .mostrar {
    margin-left: 15px;
} */

.observaciones {
    width: 100% !important;
}

.observaciones>div>textarea {
    resize: vertical;
}

/* .botonesAcciones {
    margin-top: 12px !important;

} */

.ocultar {
    display: none !important;
}

.mostrar {
    display: inline-flex !important;
    justify-self: right !important;
}

.textoObservaciones {
    margin-right: 20px;

}

.parar {
    margin-left: 10px !important;

}

.numeros {
    width: 90px !important;
    text-align: center;

}

.numeros>label {
    transform: translate(19px, 3px) scale(0.75) !important;
}

.habitacionBuscador {
    width: 140px !important;
}

.botonHabitacionBuscador {
    width: 30px !important;
    height: 30px !important;
}

.medio {
    width: 19% !important;
}

.quitarSelect>div>div {
    cursor: default;
    cursor: default;
    background-color: #ced4da6c !important;
}

.quitarSelect>div>svg {
    display: none;

}

.divFiltrosTerceros {
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
}

.divCentrarImagen {
    align-self: center;
    width: min-content;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.iconoBorrarImg {
    padding: 2px !important;
    position: absolute !important;
    background: #ffffff !important;
    border-radius: 0px 0px 0px 6px !important;
    box-shadow: -1px 1px 1px 0px rgb(0 0 0 / 20%);
    color: #00a2dc !important;
}

.iconoBorrarImg :hover {
    color: #db5e5c;
}

.botonEditarDepartamento {
    width: 154px;
    align-self: center;
}

.botonEditarDepartamento>div>input {
    cursor: pointer;
    text-align: center;
    background-color: #00A2DC !important;
    color: white;
    border-radius: 4px;
}

.containerBotonesEditarDepartamento {
    width: 35% !important;
    padding: 0px !important;
    padding-top: 7px !important;
}
.containerPdf {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.editarPartePreviewImagen {
    max-height: 120px;
    max-width: 120px;
    border-radius: 4px;
    align-self: center;
    margin: 4px;
}
input:read-only {
    cursor: default;
    background-color: #ffffffd8 !important;
}

.colorFondo>div {
    cursor: default;
    background-color: #ffffffd8 !important;
}

.alinearIzquierda {
    justify-content: flex-start;
}

.divEdicion {
    width: 33% !important;
    height: 335px !important;
}

.divEdicion>div {
    margin-top: 0px;
    padding-right: 20px;
}

.listaDepartamentos {
    overflow-x: auto;
}

.espaciado {
    padding: 10px 2px !important;
}

.observacionesEditarParte {
    height: 218px;
    padding-right: 10px !important;
}

.observacionesEditarParte>div {
    /*min-height: 100%;*/
    padding: 10px 14px !important;
}

.observacionesEditarParte>div>textarea {
    /*height: 100% !important;*/
}

.listaDepartamentosBloque {
    height: 335px;

    width: 33% !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

/* Por el momento lo comento por si se utiliza en otro lado.  */
.nuevoParteBloque {
     /* height: 340px;*/
}

.bloqueEdicion {
    height: 340px;
}


.anchoDepartamentos {
    width: 100% !important;
}

#dialogDatosParteEditar>div>div {
    min-width: 70% !important;
    min-height: 67% !important;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 96% !important;
}
/*Comentar o mover a otro archivo. Esto es Maquina*/
#dialogoInfoMaquina>div>div {
    min-width: 70% !important;
    min-height: 67% !important;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 96% !important;
}


.alturaDialog {
    max-height: 600px !important;
}

.anchoBloque {
    width: 18%;
}

.centrar {
    text-align: center;

}

.divImagen {
    height: 38px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.divBotonesImgPdf{
    display: flex;
    align-items: center;
    justify-content: center;
}
.capitalize {
    text-transform: capitalize;
}
.clicable{
    cursor: pointer;
}

.img-preview-Editar {
    max-height: 170px;
    max-width: 260px;
    border-radius: 4px;
    align-self: center;
}

.img-preview-Historico {
    max-height: 140px;
    max-width: 160px;
    height: fit-content;
    border-radius: 4px;

}

.imagenPdfEditarParte {
    display: inline-flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    padding: 0px !important;
    margin: 0px !important;
    padding-bottom: 10px !important;
}

.iconoFoto {
    position: absolute !important;
    background: #ffffff !important;
    border-radius: 0px 0px 20px 0px !important;
    color: #00a2dc !important;
    box-shadow: 2px 2px 3px 0px rgb(0 0 0 / 35%);
}

.imagenDerecha {
    float: right;
}

.selecMitad {
    width: 50% !important;
    padding: 0px 5px 3px 0px !important;
}

.noMargenes {
    margin: 0px;
}
.noBordes{
    border: 0px !important
}

#galeriaEditarParte>div>div {
    max-width: min-content;
    min-height: min-content;
    max-width: fit-content;
}

.direccion {
    padding: initial;
}

.proveedoresTextfield {
    margin: 0px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    margin-top: 0px !important;
    width: 50%;
}

.direccionFlecha {
    color: rgb(255 255 255) !important;
    background-color: #00a2dc !important;
}
.direccionFlecha:hover{
    color: rgb(255 255 255) !important;
    background-color: #007ca9 !important;
}
.observacionesAncho {
    width: 70%;
}

.chipNombres {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 60px;
    overflow-x: auto;
}

.chipNombres>div>span {
    padding-left: 6px;
    padding-right: 6px;
}

.fotosAncho {
    width: 30%;
    justify-content: center;
    align-items: center;
}

.formChip {
    width: 100%;
    padding: 7px 7px;
    justify-content: center;
}

.formChip>div {
    width: 100%;
    margin: 0;
}

.observacionesFotosDiv {
    display: flex;
}

.justificarTexto {
    text-align: justify;
}

/*
.historial-container>ul{
    max-height: 448px;
    overflow-y: auto;
}*/
#tipoOperacion {
    border-color: red;
}

.colorError {
    box-shadow: 2px 2px 2px red;
}

.liniaOperacionHistorico {
    flex: none !important;
    width: 20px !important;
    opacity: 0;
}


/*Clase de la Intranet*/
.grupotel-blue {
    background-color: #00A2DC !important;
    color: #FFFFFF !important;
}

.grupotel-sand {
    background-color: #d9cebc !important;
    color: #000000 !important;
}

.grupotel-crema {
    background-color: #6ed9ff !important;
    color: #000000 !important;
}

.claseExterna {
    transform: translate(14px, -4px) scale(0.75);
}
.separarPalabras{
    overflow-wrap: anywhere;
}
.divSoloObservacionesProveedores{
    width: 97%;
    display: inline-flex !important;
    justify-self: right !important;
    flex-direction: row;
    margin-left: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
}

/*Estilo de Historico Proveedores*/
.colorCollapse {
    background-color: whitesmoke;
}
.tipoTercerosCIF{
    max-width: 170px;
}
.observacionProveedor{
    width: 98%;;
}
.bold{
    font-weight: bold !important;
}
.botonera-historico{
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
}
.nuevo-gradient {
    /* background-color: #d13321; */
    padding: 0px !important;
    background: linear-gradient(90deg, #d13321 0%, #d13321 0.6%, rgb(255 0 0 / 0%) 0.7%)
}

.asignado-gradient {
    padding: 0px !important;
    background: linear-gradient(90deg, #a99b33 0%, #a99b33 0.6%, rgb(255 0 0 / 0%) 0.7%)
}

.en-ejecucion-gradient,
.en\ \ ejecucion-gradient,
.en\ ejecucion-gradient,
.en\-ejecucion-gradient {
    padding: 0px !important;
    background: linear-gradient(90deg, #00a2dc 0%, #00a2dc 0.6%, rgb(255 0 0 / 0%) 0.7%)
}

.terceros-gradient {
    padding: 0px !important;
    background: linear-gradient(90deg, #ffa500 0%, #ffa500 0.6%, rgb(255 0 0 / 0%) 0.7%)
}

.cerrado-gradient {
    padding: 0px !important;
    background: linear-gradient(90deg, #008000 0%, #008000 0.6%, rgb(255 0 0 / 0%) 0.7%)
}

.anulado-gradient {
    padding: 0px !important;
    background: linear-gradient(90deg, #808080 0%, #808080 0.6%, rgb(255 0 0 / 0%) 0.7%)
}


.divBotonesToogle{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.activoBoton{
    background: #e0a800 !important;
    background-color: #e0a800 !important;
    color: white !important;
    border-color: white !important;
    min-width: 100px !important;
}
.activoBoton:hover {
    background: #d29e00 !important;
    border-color: #ffd453 !important;
}
.nuevoParteHistoricoEditar{
    height: 350px;
    border: 1px solid rgb(136 134 134 / 57%) !important;
    border-radius: 4px;
    overflow-x: auto;
}