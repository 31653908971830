#logo {
    max-width: 45px!important;
    max-height: 45px!important;
    display: flex;
    margin: 0 auto;
}
.icon{
    min-width: 35px!important;
}
.icono{
    color: #FEFEFE;
}
.cerrarSesion{
    color: red !important;
}
.logout{
    display: flex !important;
    justify-content: center !important;
}
.container{
    padding: 0!important;
    margin: 0!important;
}
.clicable{
    cursor: pointer;
}
.navbar-bottom{
    margin-top: auto !important;
}
.navbar-text{
    color: #FEFEFE;
}
.navbar-text-padre span{
    color: #FEFEFE;
    font-size: 16px !important;
    font-weight: lighter !important;
}
.currLocation{
    background-color: #334f59!important;
}
.colorSeleccion:hover{
    background-color: #334f59!important;

}
/* .menuPrincipal{
    background-color: #00a2dc!important;
}  */
/* .currLocation{
    background-color: #00a2dc!important;
}
.menuPrincipal{
    background-color: #334f59!important;
} */
.textoUsuario{
    color: white;
    align-self: center;
}
.fondoCollapse{
    backdrop-filter: brightness(0.81);
}
.quitarPadding{
    padding: 0px!important;
}
.manualWorkers{
    background-color: #00a2dc !important;
    font-weight: bold;
    border-radius: 4px;
}
.manualWorkers:hover{
    background-color: #007ca9 !important;
}
.textoManual span{
    color: #FEFEFE;
    font-size: 16px !important;
    font-weight: bold !important;
}

