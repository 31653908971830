.usuariosMedio{
    width: 49% !important;
}
.usuariosflex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}
.flexWrap{
    display: flex;
    flex-wrap: wrap;
}

.hotel{
    width: 25%;
    flex-grow: 1;
    height: 30px;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-weight: bolder !important;
}

.no-permiso{
    background-color: #cdcdcd !important;
}
.no-permiso:hover{
    background-color: #9a9a9a !important;

}

.permiso{

    background-color: #00A2DC !important;
    color:#FFFFFF;
    /* font-weight: bolder !important; */
}
.permiso:hover{
    background-color: #007ca9 !important;
    color:#FFFFFF;
    /* font-weight: bolder !important; */
}
.puesto{
    background-color: #00A20C !important;
    color:#FFFFFF;
}
.puesto:hover{
    background-color: #007c09 !important;
    color:#FFFFFF;
}
.quitado{
    background-color: #dc2a00 !important;
}
.quitado:hover{
    background-color: #a90000 !important;
}