.header{
    height: 65px;
}
.header h1{
    padding: 0 !important;
    margin: 0 !important;
}
.content{
    display: flex;
    justify-content: space-between;
}

.article{
    width: 50%;
    
}
.aside{
    width: 50%;
}